/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

/**
 * External Dependencies
 */
import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

/**
 * Internal Dependencies
 */
import favicon16x16 from "../../static/favicon-16x16.png"
import favicon32x32 from "../../static/favicon-32x32.png"
import favicon64x64 from "../../static/favicon-64x64.png"
import { prepareSeoMeta } from "../utils/seo-meta"

const faviconLinks = [
  { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16x16 },
  { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32x32 },
  { rel: "shortcut icon", type: "image/png", href: favicon64x64 },
]

const SEO = ({ description, lang, meta, title, seo, post }) => {
  const { wp, wpUser } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        }

        # if there's more than one user this would need to be filtered to the main user
        wpUser {
          twitter: name
        }
      }
    `
  )

  const metaDescription = description || wp.generalSettings?.description
  const defaultTitle = wp.generalSettings?.title

  const metaFields = prepareSeoMeta(seo, title, metaDescription, meta, wp, wpUser)

  const dataSlug = (post?.slug) ? post.slug: ''

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      bodyAttributes={{
        'data-slug': dataSlug
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={faviconLinks}
      meta={metaFields.concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
