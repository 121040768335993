/* eslint-disable import/prefer-default-export */

export const prepareSeoMeta = (seo, title, description, meta, wp, wpUser) => {
  if (!seo) {
    return []
  }
  let domain = ""
  let ogImage = seo.opengraphImage?.localFile?.publicURL
  let twitterImage = seo.twitterImage?.localFile?.publicURL

  if (typeof window !== "undefined") {
    domain = window.origin
    if (ogImage) {
      ogImage = `${domain}${ogImage}`
    }

    if (twitterImage) {
      twitterImage = `${domain}${twitterImage}`
    } else {
      // default twitter image to og if it doesn't exist
      // yoast seems to always dump something into og
      twitterImage = ogImage
    }
  }
  const fieldMappings = {
    // Default to og: values as these seem to always come thru
    title: seo.title || seo.opengraphTitle,
    description: seo.metaDesc || seo.opengraphDescription,

    // These fields seems to come in by default
    "og:title": seo.opengraphTitle,
    "og:description": seo.opengraphDescription,
    "og:type": seo.opengraphType,
    "og:image": ogImage,

    // Default to og: values as these seem to always come thru
    // otherwise grab the twitter value
    "twitter:card": "summary",
    "twitter:image": twitterImage,
    "twitter:creator": wpUser?.twitter || "",
    "twitter:title": seo.twitterTitle || seo.opengraphTitle,
    "twitter:description": seo.twitterDescription || seo.opengraphDescription,
  }

  // so it retains order and easier to debug
  const fieldOrder = [
    "title",
    "description",
    "og:title",
    "og:description",
    "og:type",
    "og:image",
    "twitter:card",
    "twitter:creator",
    "twitter:title",
    "twitter:description",
    "twitter:image"
  ]

  const seoMetaFields = []
  for (const fieldName of fieldOrder) {
    const value = fieldMappings[fieldName]

    if (value) {
      const obj = {
        content: value,
      }
      if (fieldName.indexOf("og:") > -1) {
        obj.property = fieldName
      } else {
        obj.name = fieldName
      }
      seoMetaFields.push(obj)
    }
  }

  return seoMetaFields
}
